/* carousel.css */
.carousel-container {
  overflow-x: scroll;
  display: flex;
  justify-content: start; /* Align items to the start */
  padding-top: 3rem;
  scroll-behavior: smooth; /* Optional: Smooth scrolling */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  position: relative;
}

.carousel-container::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.carousel-inner {
  display: flex;
  gap: 1.5rem; /* Adjust gap as needed */
}

.carousel-item {
  min-width: calc(
    (100% / 2.5) - (1.5rem / 2.5)
  ); /* Adjust this value to show 2.5 cards, considering gaps */
  flex: 0 0 auto;
}
