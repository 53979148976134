.pagination-container {
  display: flex;
  list-style-type: none;
}

.pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 4px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}

.pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-item:hover {
  background-color: #1462ee;
  cursor: pointer;
  color: #fff;
}

.pagination-item.selected {
  background-color: #1462ee;
  color: #fff;
}

.pagination-item.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.pagination-item.disabled .arrow:hover {
  background-color: transparent;
  cursor: default;
}
