/*Global CSS*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif !important;
}

body {
  overflow-x: hidden !important;
  background-color: #fff;
}

html {
  overflow-x: hidden !important;
}

/*Font Families Import*/

/*Root Element*/
:root {
  /* Fonts */
  --inter: Inter, sans-serif;
  --roboto: Roboto, sans-serif;

  /* Font-Sizes for Laptop */
  --h1: 2.75rem;
  /*44px*/
  --h2: 2.25rem;
  /*36px*/
  --h3: 1.875rem;
  /*30px*/
  --h4: 1.5rem;
  /*24px*/
  --h5: 1.25rem;
  /*20px*/
  --h6: 1rem;
  /*16px*/
  --xl: 1.125rem;
  /* 18px */
  --lg: 1rem;
  /* 16px */
  --md: 0.875rem;
  /* 14px */
  --sm: 0.75rem;
  /* 12px */

  /*Colors*/
  --white: #ffffff;
  --blue: #1a60fe;
}

/*Media Queries ( Tablet ) for fonts*/
@media (min-width: 768px) and (max-width: 1023px) {}

/*Media Queries ( Mobile ) for fonts*/
@media (max-width: 767px) {
  :root {
    --h1: 2.05rem;
    /*36px*/
    --h2: 2rem;
    /*36px*/
    --h3: 1.875rem;
    /*30px*/
    --h4: 1.5rem;
    /*24px*/
    --h5: 1.25rem;
    /*20px*/
    --h6: 1rem;
    /*16px*/
    --xl: 1.125rem;
    /* 18px */
    --lg: 1rem;
    /* 16px */
    --md: 0.675rem;
    /* 14px */
    --sm: 0.75rem;
    /* 12px */
  }
}

.button-gradient:hover {
  background-position: right center !important;
}

@media (max-width: 1023px) {
  .bg-faq-section {
    background: none !important;
  }
}

/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  cursor: pointer;
  border-radius: 10px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #989898;
}

.chat_display_img{
  animation: float 1.5s linear infinite alternate;
}

@keyframes float {
    from {
      transform: translateY(0%);
  
    }
    to {
      transform: translateY(-10%);
    }
}

/* ------------------------------------- MESSAGE STYLING ------------------------------------------- */

.message_box h2 {
  font-size: 1.1rem;
  font-weight: 600;
}

.message_box p {
  font-size: 1rem;
  font-weight: 400;
}

.message_box ul {
  padding: 0px 20px;
}

.message_box li {
  list-style: circle;
  margin:2px 0px;
  font-weight: 500;
}